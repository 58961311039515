import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import FancyTitle from '../components/fancy-title';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import ImagePane from '../components/image-pane';

function ServicePage({ data }) {
  const servicesPage = data.allServicesYaml.edges[0].node;
  const serviceEdges = data.allServicesYaml.edges;

  function onEdgeLinkClick() {
    if (document.querySelector('html').style.scrollBehavior === 'smooth') return;
    document.querySelector('html').style.scrollBehavior = 'smooth';
    clearInterval(window.smoothInterval);
    window.smoothInterval = setInterval(() => {
      document.querySelector('html').style.scrollBehavior = 'auto';
      clearInterval(window.smoothInterval);
    }, 1000);
  }

  const [theme, setTheme] = useState('dark');

  const handleThemeChange = useCallback((event) => {
    setTheme(event.theme);
  }, []);

  useEffect(() => {
    window.addEventListener('handleThemeChange', handleThemeChange);
    return () => {
      window.removeEventListener('handleThemeChange', handleThemeChange);
    };
  }, [handleThemeChange]);

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: servicesPage.title, fullWidth: true });
        return (
          <div className="px-4 lg:px-16 pb-[100px] lg:pb-60">
            <Seo title="Leistungen unserer Shopify Agentur" description="Mit mehr als 10 Jahren Erfahrung bringen wir Sie an die Spitze, vom Shopify-Design und der Entwicklung bis hin zu Marketingstrategien und Apps zur Erweiterung des Shops. Wir erstellen Ihren perfekten Shopify-Shop." />
            <h1 className="sr-only">Leistungen</h1>
            {
              serviceEdges
              && (
              <div className="pt-20 lg:pt-36 max-w-fit mx-auto">
                {
                  serviceEdges.map((edge) => {
                    const service = edge.node;
                    return (
                      <Link
                        key={`#${service.slug}`}
                        to={`#${service.slug}`}
                        onClick={(event) => onEdgeLinkClick(event)}
                        className="inline-block px-4 py-2 font-bold"
                      >
                        <span dangerouslySetInnerHTML={{ __html: service.name }} />
                      </Link>
                    );
                  })
                }
              </div>
              )
            }

            { serviceEdges && serviceEdges.map((edge, index) => {
              const service = edge.node;
              const image = getImage(service.image);
              let svgImage;
              if (service[`image_${theme}`]) {
                svgImage = service[`image_${theme}`]?.extension === 'svg' && service[`image_${theme}`]?.publicURL;
              } else {
                svgImage = service.image.extension === 'svg' && service.image.publicURL;
              }
              return (
                <div
                  key={service.slug}
                  id={service.slug}
                  className={`mt-[100px] ${index > 0 ? 'lg:mt-60' : 'lg:mt-44'} scroll-mt-24`}
                >
                  <ImagePane
                    desktopImagePosition={index % 2 === 0 ? 'right' : 'left'}
                    image={(
                      <Link to={`/services/${service.slug}`} className="w-full">
                        <div className="w-full">
                          {!svgImage && image
                            && (
                            <GatsbyImage
                              image={image}
                              alt={service.title}
                              className="w-full "
                            />
                            )}
                          {svgImage
                            && (
                            <div className="px-10 py-10 xl:py-0">
                              <img
                                src={svgImage}
                                alt={service.title}
                                width="126"
                                height="26"
                                className="w-full object-contain"
                                loading="lazy"
                              />
                            </div>
                            )}
                        </div>
                      </Link>
                    )}
                    title={(
                      <FancyTitle
                        tag="h2"
                        text={service.name}
                        className="mb-8 lg:mb-0"
                      />
                    )}
                    desktopImageWidth="w-full lg:w-3/5"
                    desktopTitleWidth="w-full lg:w-2/5"
                    content={(
                      <>
                        <p dangerouslySetInnerHTML={{ __html: service.excerpt }} className="my-4" />
                        <Link to={`/services/${service.slug}`} className="button-primary inline-block mt-4">MEHR</Link>
                      </>
                    )}
                  />
                </div>
              );
            })}
          </div>
        );
      }}
    </LayoutConsumer>
  );
}

export default ServicePage;

export const servicesQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allServicesYaml {
      edges {
        node {
          name
          excerpt
          title
          slug
          icon
          description
          url
          link_text
          image {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 2000,
                placeholder: BLURRED,
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          image_light {
            extension
            publicURL
          }
          image_dark {
            extension
            publicURL
          }
          link
          subtitle
          subdescription
          usps {
            title
            icon
            description
          }
          steps {
            title
            description
            items {
              title
              description
              icon
            }
            image {
              name
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          sample {
            title
            description
            background_color
            image {
              name
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 2000,
                  placeholder: BLURRED,
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            link_to
          }
          references {
            title
            items {
              ref_1
              ref_2
              ref_3
              ref_4
            }
          }
        }
      }
    }
  }
`;
